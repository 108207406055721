import moment from 'moment';

import { FromTo } from 'types/DateRange';

export const getDate = (epochDate: number | string): string => {
  const date = new Date(epochDate);

  return `${date.toLocaleDateString('en-US', {
    month: 'numeric',
    year: 'numeric',
    day: 'numeric',
  })}`;
};

export const formatDate = (date, showTime = false, showDate = true) => {
  if (!showTime && !showDate) {
    throw new Error('At least one of showTime or showDate must be true');
  }

  const momentDate = moment(date);

  let formattedResult = '';

  if (showDate) {
    formattedResult = momentDate.local().format('MMM D, YYYY');
  }

  if (showTime) {
    formattedResult += showDate ? ' ' : '';
    formattedResult += momentDate.local().format('HH:mm:ss');
  }

  return formattedResult;
};

export const getDateByTwoNumbers = (epochDate: number | string): string => {
  const date = new Date(epochDate);

  const month = date.getMonth() + 1;
  const day = date.getDate().toString();

  return `${month < 10 ? '0' + month : month}/${
    day.length < 2 ? '0' + day : day
  }/${date.getFullYear().toString()}`;
};

export const getEpochDate = (
  date: FromTo | string | number,
  hour = 0,
  second = 0,
  millisecond = 0
): number => {
  if (!date) return;
  if (typeof date === 'number') {
    return date;
  } else if (typeof date === 'string') {
    const [year, month, day] = date.split('-');
    return new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      hour,
      second,
      millisecond
    ).getTime();
  } else if (typeof date === 'object') {
    return new Date(
      date.year,
      date.month - 1,
      date.day,
      hour,
      second,
      millisecond
    ).getTime();
  }
};

export const getFullISODate = (epochTime?: number | string) =>
  epochTime ? new Date(epochTime).toISOString() : epochTime;

export const getShortISOFromDate = (dateString: string) =>
  dateString?.slice(0, 10);

export const getCurrentShortISODate = () =>
  new Date().toISOString().slice(0, 10);

export const getDateFromUTC = (dateString: string) => {
  return moment(dateString).format('YYYY-MM-DD HH:mm:ss');
};
